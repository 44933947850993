import {
  ArrowRightSvg,
  Button,
  ButtonVariant,
  ChevronIcon,
  EFont,
  EFontSize,
  ESpacing,
  Font,
} from "@hkexpressairwayslimited/ui/src";
import { Box, Divider, Grid, Stack, SvgIcon } from "@mui/material";
export interface FromButtonsProps {
  submitLabel?: string | JSX.Element | JSX.Element[];
  onSummaryClick?: () => void;
  onSubmit?: () => void;
  currency?: string;
  totalCost?: number | string | JSX.Element;
  left?: number;
  totalText?: string | JSX.Element | JSX.Element[];
  summaryText?: string | JSX.Element | JSX.Element[];
  disable?: boolean;
  hideFormButtonPrice?: boolean;
  hideSummary?: boolean;
  bottom?: number;
}

export function FormButtons({
  submitLabel = "submit",
  currency = "",
  totalCost = 0,
  onSummaryClick = () => {},
  onSubmit,
  left = 0,
  totalText = "",
  summaryText = "",
  disable = false,
  hideFormButtonPrice = false,
  hideSummary = false,
  bottom,
}: FromButtonsProps) {
  return (
    <Grid
      container
      sx={{
        position: "fixed",
        bottom: bottom ?? 0,
        left: [0, 0, "unset", "unset"],
        width: (t) => ["100%", "100%", "96%", `calc(${t.breakpoints.values.lg}px + ${left * 2}px + 16px) !important`],
        marginLeft: ["unset", "unset", "unset", `-${left + 16}px`], //-${ESpacing._3xl}
        marginY: ESpacing._md,
        marginBottom: [0, 0, ESpacing._md],
        padding: [ESpacing._s, `${ESpacing._s} ${ESpacing._md}`, `${ESpacing._s} ${ESpacing._md}`],
        color: "neutral.white",
        backgroundColor: "purple.default",
        borderRadius: ["unset", "unset", ESpacing._5xl],
        zIndex: 998,
      }}
      alignItems='center'
      justifyContent='space-between'
    >
      <Grid xs={12} md='auto'>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent={["space-between", "space-between", "flex-start"]}
          marginBottom={[ESpacing._s, ESpacing._s, 0]}
          spacing={ESpacing._2xs}
        >
          {!hideFormButtonPrice && (
            <Stack direction='row' alignItems='center' spacing={ESpacing._2xs} display={"flex"}>
              <Font variant={EFont.p1}>{totalText}</Font>
              {/* <Font variant={EFont.h1}> */}
              {currency} {totalCost}
              {/* </Font> */}
            </Stack>
          )}
          {!hideSummary && (
            <>
              <Box
                sx={{
                  display: ["none", "none", "block"],
                }}
              >
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{
                    height: EFontSize.p1,
                    borderColor: "neutral.borderNeutral",
                  }}
                />
              </Box>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                <Font
                  variant={EFont.p1}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={onSummaryClick}
                >
                  {`${summaryText}`}
                </Font>
                <SvgIcon
                  sx={{
                    cursor: "pointer",
                    transform: "rotate(90deg)",
                  }}
                  onClick={onSummaryClick}
                >
                  <ChevronIcon />
                </SvgIcon>
              </Box>
            </>
          )}
        </Stack>
      </Grid>
      <Grid xs={12} md='auto' item sx={{ minWidth: ["unset", "unset", "348px"] }}>
        <Button
          fullWidth
          submit={onSubmit ? false : true}
          variant={ButtonVariant.Secondary}
          borderRadius={ESpacing._md}
          onClick={onSubmit && onSubmit}
          disabled={disable}
        >
          <Stack spacing={ESpacing._2xs} direction='row' alignItems='center' justifyContent='center'>
            <Font variant={EFont.p1} color='purple.default' fontWeight={"700"}>
              {submitLabel}
            </Font>
            <SvgIcon>
              <ArrowRightSvg />
            </SvgIcon>
          </Stack>
        </Button>
      </Grid>
    </Grid>
  );
}
