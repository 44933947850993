import {
  AppleIcon,
  Box,
  Button,
  COUNTRY_CODE,
  CompositeMobileField,
  EMAIL_REGEXP,
  ESpacing,
  FieldInput,
  FieldInputGroup,
  Font,
  Form,
  FormProps,
  Grid,
  Link,
  Radio,
  RadioGroup,
  SvgIcon,
  Text,
  TextInput,
  TextSize,
  isPhoneValid,
  useForm,
} from "@hkexpressairwayslimited/ui/src";
import { Divider, Stack } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Theme, styled } from "@mui/material/styles";
import type { Meta } from "@storybook/react";
import { FormSubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { CathayIcon, CloseIcon } from "../../../assets/icons";

import { I18nStr } from "@hkexpressairwayslimited/ui/src";
import { useSearchParams } from "next/navigation";
import { useMemo } from "react";
import classes from "./Membership.module.scss";

const CathayButton = styled(Button)(({ theme }: { theme: Theme }) => {
  return {
    outline: "1px solid #006B6E !important",
    color: "#006B6E !important",
  };
});

const AppleButton = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "10px 20px",
  backgroundColor: "#000",
  color: "#fff",
  border: "1px solid #000",
  borderRadius: "5px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#333",
  },
}));

const meta: Meta<FormProps> = {
  component: Form,
};

export default meta;

const countryCodeOptions = COUNTRY_CODE;

export type SignInBoxProps = {
  onSignInBox?: (formValues: Parameters<FormSubmitHandler<FormValues1>>[0]) => void;
  onClickForgetPassword: () => void;
  errorMsg?: I18nStr;
  isDisplayClose?: boolean;
  onCloseLoginNow?: () => void;
  handleCxMemberClick: () => void;
  handleAppleMemberClick: () => void;
  i18nContent: {
    title: I18nStr;
    desc: I18nStr;
    method: {
      options: { value: string; label: I18nStr }[];
    };
    emailField: {
      placeholder: I18nStr;
      helperText: I18nStr;
      required: string;
    };
    mobilePhoneField: {
      placeholder: I18nStr;
      helperText: I18nStr;
      required: string;
      countryCode: {
        required: string;
      };
      options: Array<{ value: string; label: I18nStr }>;
    };
    passwordField: {
      placeholder: I18nStr;
      helperText: I18nStr;
      required: string;
    };
    fwd: I18nStr;
    submit: I18nStr;
    text: I18nStr;
    link: I18nStr;
    other: I18nStr;
    cxText: I18nStr;
    appleText: I18nStr;
  };
};
enum Fields {
  Method = "method",
  Email = "email",
  Mobile = "mobile",
  CountryCode = "countryCode",
  MobileNumber = "mobileNumber",
  Password = "password",
}
type FormValues1 = {
  [Fields.Method]: string;
  [Fields.Email]?: string;
  [Fields.Mobile]?: {
    [Fields.CountryCode]?: string;
    [Fields.MobileNumber]?: string;
  };
  [Fields.Password]: string;
};

const defaultValues1 = {
  [Fields.Method]: "email",
  [Fields.Email]: "",
  [Fields.Mobile]: {
    [Fields.CountryCode]: "852",
    [Fields.MobileNumber]: "",
  },
  [Fields.Password]: "",
};

const SignInBox = (props: SignInBoxProps) => {
  const searchParams = useSearchParams();
  const content = useMemo(() => {
    return {
      methodRadio: props.i18nContent?.method,
      emailField: props.i18nContent?.emailField,
      mobilePhoneField: props.i18nContent?.mobilePhoneField,
      passwordField: props.i18nContent?.passwordField,
    };
  }, [props.i18nContent]);
  const formHook1 = useForm<FormValues1>({
    defaultValues: defaultValues1,
    schema: yup.object().shape({
      [Fields.Method]: yup.string().required(),
      [Fields.Email]: yup.string().when([Fields.Method], {
        is: (v: string) => v === "email",
        then: (schema) =>
          schema.matches(EMAIL_REGEXP, content.emailField.required).required(content.emailField.required),
        otherwise: (schema) => schema.notRequired(),
      }),
      [Fields.Mobile]: yup.object().when([Fields.Method], {
        is: (v: string) => v === "phone",
        then: (schema) =>
          yup
            .object()
            .shape({
              [Fields.CountryCode]: yup.string().required(content.mobilePhoneField.countryCode.required),
              [Fields.MobileNumber]: yup.string().required(content.mobilePhoneField.required),
            })
            .test("isPhoneValid", content.mobilePhoneField.required, (value, context) => {
              return isPhoneValid(value);
            }),
        otherwise: (schema) =>
          yup.object().shape({
            [Fields.CountryCode]: yup.string().notRequired(),
            [Fields.MobileNumber]: yup.string().notRequired(),
          }),
      }),
      [Fields.Password]: yup.string().required(content.passwordField.required),
      // .test("follow-rules", content.passwordField.required, (value) => {
      //   const characterLength = /^.{8,15}$/.test(value);
      //   const setSpecialCharacter = /[^a-zA-Z\d,.~]+/.test(value);
      //   const upperCaseCharacter = /^(?=.*?[A-Z])/.test(value);
      //   const lowerCaseCharacter = /^(?=.*?[a-z])/.test(value);
      //   const numericalDigit = /^(?=.*?\d)/.test(value);
      //   const noSpace = /\s/.test(value);
      //   const period = /^(?=.*[,.~])/.test(value);
      //   return (
      //     characterLength &&
      //     setSpecialCharacter &&
      //     upperCaseCharacter &&
      //     lowerCaseCharacter &&
      //     numericalDigit &&
      //     !noSpace &&
      //     !period
      //   );
      // }),
    }),
  });

  const method1 = formHook1.watch(Fields.Method);
  const isShowAppleButton = useMemo(() => {
    const isApp = searchParams.get("device") == "app";
    if (!isApp) {
      return false;
    }
    const userAgent = typeof window !== "undefined" && window.navigator && window.navigator.userAgent;
    if (userAgent) {
      const isMobile = /AppleWebKit.*Mobile.*/.test(userAgent);
      const isIos = /iPad|iPhone/.test(userAgent);
      return isApp && isMobile && isIos;
    } else {
      return false;
    }
  }, [searchParams]);
  // const text1 = useMemo(() => {
  //   // const isApp = searchParams.get('device') == 'app';
  //   // if (!isApp) {
  //   //   return false;
  //   // }
  //   const userAgent = typeof window !== "undefined" && window.navigator && window.navigator.userAgent;
  //   if (userAgent) {
  //     return userAgent;
  //   } else {
  //     return "undefined";
  //   }
  // }, [searchParams]);

  return (
    <Box id='sign-in-box' className={classes.formStory}>
      <Form<FormValues1>
        formHook={formHook1}
        onSubmit={(formValues) => {
          props.onSignInBox?.(formValues);
        }}
      >
        <Grid>
          <Box className={classes.formStory_form_title}>
            <Font>{props.i18nContent?.title}</Font>
            {props.isDisplayClose && (
              <SvgIcon sx={{ width: ESpacing._sm, height: ESpacing._sm }} onClick={props.onCloseLoginNow}>
                <CloseIcon />
              </SvgIcon>
            )}
          </Box>
          <Box className={classes.formStory_form_content}>
            <Box sx={{ mt: "-6px" }}>
              <Text size={TextSize.P3Regular}>{props.i18nContent?.desc}</Text>
            </Box>
            <Box sx={{ mt: "-14px" }}>
              <FieldInput name={Fields.Method} fullWidth>
                <RadioGroup row>
                  {content.methodRadio.options.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FieldInput>
            </Box>

            {method1 == "email" ? (
              <Box sx={{ mt: `-${ESpacing._xs}` }}>
                <FieldInputGroup helperText={[content.emailField.helperText]} names={[Fields.Email]} fullWidth>
                  <TextInput placeholder={content.emailField.placeholder} fullWidth type='email' />
                </FieldInputGroup>
              </Box>
            ) : (
              <Box sx={{ mt: `-${ESpacing._xs}` }}>
                <FieldInputGroup helperText={content.mobilePhoneField.helperText} names={[Fields.Mobile]} fullWidth>
                  <CompositeMobileField countryCodeProps={{ options: content.mobilePhoneField.options }} />
                </FieldInputGroup>
              </Box>
            )}
            <FieldInputGroup helperText={[content.passwordField.helperText]} names={[Fields.Password]} fullWidth>
              <TextInput isPassword placeholder={content.passwordField.placeholder} fullWidth />
            </FieldInputGroup>
            <Link href='' onClick={() => props.onClickForgetPassword()}>
              {props.i18nContent?.fwd}
            </Link>
            {props.errorMsg ? <Text color={"red"}>{props.errorMsg}</Text> : null}

            <Stack rowGap={2}>
              <Button fullWidth submit>
                {props.i18nContent?.submit}
              </Button>
            </Stack>
            <Box display={"flex"} columnGap={1}>
              <Text>{props.i18nContent?.text}</Text>
              <Link
                href={`/member/signup`}
                // href={`${window.location.origin}${window.location.pathname.substr(0, 3)}/member/signup`}
                target='_blank'
              >
                {props.i18nContent?.link}
              </Link>
            </Box>
            <Box sx={{ position: "relative", mb: "10px" }}>
              <Divider
                sx={{
                  height: "1px",
                  backgroundColor: "neutral.borderNeutral",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "neutral.white",
                }}
              >
                {props.i18nContent?.other}
              </Box>
            </Box>
            <Box>
              <CathayButton outlined fullWidth leadingIcon={<CathayIcon />} onClick={props.handleCxMemberClick}>
                <Font fontWeight='FontWeightLight'>{props.i18nContent?.cxText}</Font>
              </CathayButton>
            </Box>
            <AppleButton
              id='appleid-signin'
              className='signin-button'
              data-color='black'
              data-border='true'
              data-type='sign-in'
              onClick={props.handleAppleMemberClick}
              sx={{ display: isShowAppleButton ? "flex" : "none" }}
            >
              <SvgIcon sx={{ width: "20px", height: "20px", marginRight: "5px", mt: ESpacing._3xs }}>
                <AppleIcon />
              </SvgIcon>
              <Font fontWeight='FontWeightLight'>{props.i18nContent?.appleText}</Font>
            </AppleButton>
            {/* <Text style={{ backgroundColor: "white", padding: 16 }}>{text1}</Text> */}
          </Box>
        </Grid>
      </Form>
    </Box>
  );
};

export { SignInBox };
