import { useCallback, useState } from "react";
import { Box } from "../..";

import { ForgetPasswordBox, ForgetPasswordBoxProps } from "./ForgetPasswordBox";
import { SignInBox, SignInBoxProps } from "./SignInBox";

type SignInProps = {
  onSignIn?: SignInBoxProps["onSignInBox"];
  onForgetPassword?: ForgetPasswordBoxProps["onForgetPassword"];
  signInErrorMsg?: string | JSX.Element | JSX.Element[];
  forgetPwdErrorPwdMsgMsg?: string | JSX.Element | JSX.Element[];
  isDisplayClose?: boolean;
  onCloseLoginNow?: () => void;
  handleCxMemberClick: () => void;
  handleAppleMemberClick: () => void;
  i18nContent: SignInBoxProps["i18nContent"];
  i18nContentPassword: ForgetPasswordBoxProps["i18nContentPassword"];
};

const SignInAndForgetPwd = (props: SignInProps) => {
  const [isLogin, setIsLogin] = useState<string>("signIn");
  const onClickForgetPassword = useCallback(() => {
    setIsLogin("forgetPwd");
  }, [setIsLogin]);
  const onClickSignIn = useCallback(() => {
    setIsLogin("signIn");
  }, [setIsLogin]);
  const onClickAppleSignIn = useCallback(() => {
    setIsLogin("signIn");
  }, [setIsLogin]);
  return (
    <>
      <Box sx={isLogin === "signIn" ? {} : { display: "none" }}>
        <SignInBox
          errorMsg={props.signInErrorMsg}
          onSignInBox={props.onSignIn}
          onClickForgetPassword={onClickForgetPassword}
          isDisplayClose={props.isDisplayClose}
          onCloseLoginNow={props.onCloseLoginNow}
          i18nContent={props.i18nContent}
          handleCxMemberClick={props.handleCxMemberClick}
          handleAppleMemberClick={props.handleAppleMemberClick}
        />
      </Box>
      <Box sx={isLogin === "forgetPwd" ? {} : { display: "none" }}>
        <ForgetPasswordBox
          i18nContentPassword={props.i18nContentPassword}
          errorMsg={props.forgetPwdErrorPwdMsgMsg}
          onForgetPassword={props.onForgetPassword}
          onClickSignIn={onClickSignIn}
          onClickAppleSignIn={onClickAppleSignIn}
        />
      </Box>
    </>
  );
};

export { SignInAndForgetPwd };
